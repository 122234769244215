//
// wizard.js
// Dashkit module
//

import { Tab } from "bootstrap";
import { method } from "lodash";

async function setWizard() {
    const toggles = document.querySelectorAll('[data-toggle="wizard"]');
    let form;
    toggles.forEach((toggle) => {
        toggle.addEventListener("click", async function (e) {
            e.preventDefault();
            let valid = true;
            if (toggle.dataset.wizzard != null) {
                form = document.getElementById(toggle.dataset.wizzard);
                const inputs = form.querySelectorAll("input[required]");
                inputs.forEach((input) => {
                    //if input email is valid
                    if (input.type == "email") {
                        //accept emails with plus sign
                        const regex = new RegExp(
                            "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
                        );
                        if (!regex.test(input.value)) {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "text") {
                        if (input.value == "") {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "password") {
                        if (input.value == "") {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "checkbox") {
                        if (!input.checked) {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "file") {
                        if (input.files.length == 0) {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "radio") {
                        const radios = form.querySelectorAll(
                            `input[name="${input.name}"]`
                        );
                        let checked = false;
                        radios.forEach((radio) => {
                            if (radio.checked) {
                                checked = true;
                            }
                        });
                        if (!checked) {
                            radios.forEach((radio) => {
                                radio.classList.add("is-invalid");
                            });
                            valid = false;
                        } else {
                            radios.forEach((radio) => {
                                radio.classList.remove("is-invalid");
                            });
                        }
                    }
                    if (input.type == "url") {
                        //check if url is valid
                        //Allow websites without http or https
                        //URLs with subdomains
                        //URLs with paths
                        //URLs with query strings or hash tags
                        //URLs with ports
                        //check if url is valid with regex
                        const regex = new RegExp(
                            "^(https?:\\/\\/)?" + // protocol
                                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
                                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                                "(\\#[-a-z\\d_]*)?$",
                            "i"
                        );
                        if (!regex.test(input.value)) {
                            input.classList.add("is-invalid");
                            valid = false;
                        } else {
                            input.classList.remove("is-invalid");
                        }
                    }
                    if (input.type == "number") {
                        if (
                            input.value == "" ||
                            (input.min != "" && input.value < input.min) ||
                            (input.max != "" && input.value > input.max)
                        ) {
                            input.classList.add("is-invalid");
                            valid = false;
                        }
                        if (input.step != "" && input.value % input.step != 0) {
                            input.classList.add("is-invalid");
                            valid = false;
                        }
                    }
                });
                const selects = form.querySelectorAll("select[required]");
                selects.forEach((select) => {
                    if (select.value == "") {
                        select.classList.add("is-invalid");
                        valid = false;
                    } else {
                        select.classList.remove("is-invalid");
                    }
                });
            }
            if (
                toggle.dataset.wizzard != null &&
                form != null &&
                form.dataset.validate == "remote" &&
                valid
            ) {
                new Promise((resolve, reject) => {
                    checkInputsRemote(form, toggle);
                    resolve();
                });
            } else {
                if (valid) {
                    showNextTab(toggle);
                }
            }
        });
    });
}

function showNextTab(toggle) {
    const tab = new Tab(toggle);
    tab.show();
    toggle.closest(".tab-pane").classList.remove("active");
    toggle.closest(".tab-pane").classList.remove("show");
    const target = document.querySelector(toggle.getAttribute("href"));
    target.classList.add("active");
    target.classList.add("show");
}

async function checkInputsRemote(form, toggle) {
    console.log("checkInputsRemote");
    console.log({ form });
    const inputs = form.querySelectorAll("input[required][data-validate]");
    let data = {};
    inputs.forEach((input) => {
        console.log({ input });
        data = {
            ...data,
            [input.name]: input.value,
        };
    });
    if (form.dataset.validate == "remote") {
        let validMethod = form.dataset.method;
        let validUrl = form.dataset.url;
        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
        });
        $.ajax({
            url: validUrl,
            type: validMethod,
            data: data,
            success: function (data) {
                showNextTab(toggle);
            },
            error: function error(data) {
                console.log({ data });
                if (data?.responseJSON?.errors) {
                    for (const [key, value] of Object.entries(
                        data.responseJSON?.errors
                    )) {
                        var text = "";
                        // toastr.error(value);
                        //put invalid class on input
                        const input = form.querySelector(
                            `input[name="${key}"]`
                        );
                        input.classList.add("is-invalid");
                        //change brother element .invalid-feedback text
                        const invalidFeedback =
                            input.parentElement.querySelector(
                                ".invalid-feedback"
                            );
                        //join array to string
                        if (Array.isArray(value)) {
                            text = value.join("<br>");
                        } else {
                            text = value;
                        }
                        invalidFeedback.innerHTML = text;
                    }
                }
            },
        });
    }
}
window.setWizard = setWizard;
setWizard();
