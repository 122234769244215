//
// popover.js
// Theme module
//

import { Tooltip } from 'bootstrap';
function setTooltips() {  
  const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

  tooltips.forEach(tooltip => {
    new Tooltip(tooltip);
  });
}

window.setTooltips = setTooltips;
setTooltips()