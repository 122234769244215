// Theme
import "./autosize";
import "./bootstrap-theme";
import "./checklist";
import "./chart";
import "./select2";
import "./dropzone";
import "./flatpickr";
import "./highlight";
import "./inputmask";
import "./kanban";
import "./list";
import "./navbar-collapse";
import "./navbar-dropdown";
import "./popover";
import "./tooltip";
import "./quill";
import "./wizard";
import "toastr";
window.$ = window.jQuery = require("jquery");
