import {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
  } from 'bootstrap';
  
  // Make available globally
  window.Alert = Alert;
  window.Button = Button;
  window.Carousel = Carousel;
  window.Collapse = Collapse;
  window.Dropdown = Dropdown;
  window.Modal = Modal;
  window.Offcanvas = Offcanvas;
  window.Popover = Popover;
  window.ScrollSpy = ScrollSpy;
  window.Tab = Tab;
  window.Toast = Toast;
  window.Tooltip = Tooltip;